import React, { useState } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { useHistory } from "react-router-dom";
import { addBlooddonation } from "../../store/actions/blooddonations_action";
import AddForm from "../../components/common/AddForm";
import { upload } from "../../shared/upload";
import { initialValues, inputFields } from "../../shared/enums/blooddonations_enum";
const AddBlooddonation = ({ addBlooddonation }) => {
    let history = useHistory();
    const [featuredImage, setFeaturedImage] = useState(null);
    const [gallery, setGallery] = useState(null);

    const submitBlooddonation = async (values) => {
        if (featuredImage || gallery) {
            const images = await upload(featuredImage, gallery);
            if (images) {
                if (images.featured_image) {
                    values.featured_image = images.featured_image;
                }
                if (images.gallery_images) {
                    values.gallery = images.gallery_images;
                }
            }
        }
        await addBlooddonation(values);
        history.push("/blooddonations");
    };

    return (
        <div className="pace-done">
            <div>
                <Header />
                <BreadCrumb
                    title="Add Blooddonation"
                    mainLinkTitle="Blooddonations"
                    mainLinkUrl="/blooddonations"
                    activeLink="Add"
                />
            </div>
            <div className="container-fluid">
                <div className="col-lg-9">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Blooddonation</h4>
                            <p className="card-title-desc">Enter Details to add Blooddonation</p>
                        </div>
                        <AddForm
                            edit={false}
                            featuredImage={featuredImage}
                            gallery={gallery}
                            setFeaturedImage={setFeaturedImage}
                            setGallery={setGallery}
                            submitForm={submitBlooddonation}
                            inputFields={inputFields}
                            initialValues={initialValues}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({ alert: state.alert });

const mapDispatchToProps = { addBlooddonation };

export default connect(mapStateToProps, mapDispatchToProps)(AddBlooddonation);
