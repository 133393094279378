export const PAGE_TITLE = "Testimonials";
export const PAGE_SINGLE_TITLE = "Testimonial";
export const LINK_URL = "testimonials";
export const SEARCH_TERMS = ["title", "meta_title"];
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Title",
  },

  testimonial: {
    type: "text",
    required: true,
    title: "Testimonial",
  },
  featured_image: {
    type: "file",
    required: false,
    title: "Image",
  },
  approved: {
    type: "string",
    required: true,
    title: "Approved",
    inputType: "number",
  },
};
export const initialValues = {
  name: "",
  approved: "",
  testimonial: "",
};

export const view_all_table = [
  { name: "Name", value: "name" },
  { name: "Testimonial", value: "testimonial" },
  { name: "Approved", value: "approved" },
];
