import React, { useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import renderHTML from "react-render-html";
import { getSpecialdonation } from "../../store/actions/specialdonations_action";
import Spinner from "../../components/layout/Spinner";
import SingleItem from "../../components/template/SingleItem";
import { Link } from "react-router-dom";
const ViewSpecialdonation = ({ getSpecialdonation, match, specialdonation: { loading, specialdonation } }) => {
    useEffect(() => {
        getSpecialdonation(match.params.id);
    }, [match]);
    return (
        <div className="pace-done">
            <div>
                <Header />
                <BreadCrumb
                    title="Specialdonations"
                    mainLinkTitle="Specialdonations"
                    mainLinkUrl="/specialdonations"
                    activeLink="View"
                />
                {!loading ? (
                    specialdonation && (
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="card">
                                        <div className="card-header">
                                            <div>
                                                <h4 className="card-title">
                                                    {specialdonation.name} <span>View</span>
                                                </h4>
                                                <p className="card-title-desc">
                                                    <Link to="/specialdonations" className="btn btn-soft-light">
                                                        <i className="fa fa-angle-left"></i> Specialdonations
                                                    </Link>
                                                    <Link
                                                        to={`/specialdonations/${specialdonation.id}/edit`}
                                                        className="btn btn-soft-light"
                                                    >
                                                        <i className="fa fa-edit"></i>
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <SingleItem title="name" value={specialdonation.name} />
                                            <SingleItem title="phone" value={specialdonation.phone} />
                                            <SingleItem title="email" value={specialdonation.email} />
                                            <SingleItem title="product" value={specialdonation.product} />
                                            <SingleItem title="message" value={specialdonation.message} />
                                            <SingleItem title="products in numbers" value={specialdonation.products_in_numbers} />


                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    )
                ) : (
                    <div>
                        <Spinner />
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({ specialdonation: state.specialdonation });

const mapDispatchToProps = { getSpecialdonation };

export default connect(mapStateToProps, mapDispatchToProps)(ViewSpecialdonation);
