export const PAGE_TITLE = "Contacts";
export const PAGE_SINGLE_TITLE = "Contact";
export const LINK_URL = "contacts";
export const SEARCH_TERMS = ["title", "meta_title"];
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Title",
  },
  phone: {
    type: "string",
    required: true,
    title: "Phone",
  },
  email: {
    type: "string",
    required: true,
    title: "Email",
  },
  message: {
    type: "text",
    required: false,
    title: "Message",
  },
};
export const initialValues = {
  name: "",
  phone: "",
  email: "",
  message: "",
};

export const view_all_table = [
  { name: "Name", value: "name" },
  { name: "Phone", value: "phone" },
  { name: "Email", value: "email" },
  { name: "Message", value: "message" },
];
