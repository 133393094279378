import React, { useEffect } from "react";
import "./assets/css/bootstrap.min.css";
import "./assets/css/app.min.css";
import "./assets/css/custom.css";
import "./assets/css/wysiwyg.css";
import "./assets/css/responsive.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import Login from "./containers/login/Login";
import { loadUser } from "./store/actions/auth";
import BeforeLoginRoutes from "./shared/Routes/BeforeLoginRoutes";
import PrivateRoutes from "./shared/Routes/PrivateRoutes";
import Dashboard from "./containers/dashboard/Dashboard";
import PageNotFound from "./containers/notfound/PageNotFound";
import AddBlog from "./containers/blogs/AddBlog";
import AllBlogs from "./containers/blogs/AllBlogs";
import ViewBlog from "./containers/blogs/ViewBlog";
import EditBlog from "./containers/blogs/EditBlog";
import Profile from "./containers/profile/Profile";
import AddBanner from "./containers/banners/AddBanner";
import AllBanners from "./containers/banners/AllBanners";
import ViewBanner from "./containers/banners/ViewBanner";
import EditBanner from "./containers/banners/EditBanner";
import AddProject from "./containers/projects/AddProject";
import AllProjects from "./containers/projects/AllProjects";
import ViewProject from "./containers/projects/ViewProject";
import EditProject from "./containers/projects/EditProject";
import AddCause from "./containers/causes/AddCause";
import AllCauses from "./containers/causes/AllCauses";
import ViewCause from "./containers/causes/ViewCause";
import EditCause from "./containers/causes/EditCause";
import AddDonation from "./containers/donations/AddDonation";
import AllDonations from "./containers/donations/AllDonations";
import ViewDonation from "./containers/donations/ViewDonation";
import EditDonation from "./containers/donations/EditDonation";
import AddVolunteer from "./containers/volunteers/AddVolunteer";
import AllVolunteers from "./containers/volunteers/AllVolunteers";
import ViewVolunteer from "./containers/volunteers/ViewVolunteer";
import EditVolunteer from "./containers/volunteers/EditVolunteer";
import AddContact from "./containers/contacts/AddContact";
import AllContacts from "./containers/contacts/AllContacts";
import ViewContact from "./containers/contacts/ViewContact";
import EditContact from "./containers/contacts/EditContact";
import AddNewsletter from "./containers/newsletters/AddNewsletter";
import AllNewsletters from "./containers/newsletters/AllNewsletters";
import ViewNewsletter from "./containers/newsletters/ViewNewsletter";
import EditNewsletter from "./containers/newsletters/EditNewsletter";
import AddEnquirie from "./containers/enquiries/AddEnquirie";
import AllEnquiries from "./containers/enquiries/AllEnquiries";
import ViewEnquirie from "./containers/enquiries/ViewEnquirie";
import EditEnquirie from "./containers/enquiries/EditEnquirie";
import AddPatient from "./containers/patients/AddPatient";
import AllPatients from "./containers/patients/AllPatients";
import ViewPatient from "./containers/patients/ViewPatient";
import EditPatient from "./containers/patients/EditPatient";
import AddMobile from "./containers/mobile-banners/AddMobile";
import AllMobiles from "./containers/mobile-banners/AllMobiles";
import ViewMobile from "./containers/mobile-banners/ViewMobile";
import EditMobile from "./containers/mobile-banners/EditMobile";
import AddEvent from "./containers/events/AddEvent";
import AllEvents from "./containers/events/AllEvents";
import ViewEvent from "./containers/events/ViewEvent";
import EditEvent from "./containers/events/EditEvent";
import AddRelease from "./containers/press-releases/AddRelease";
import AllReleases from "./containers/press-releases/AllReleases";
import ViewRelease from "./containers/press-releases/ViewRelease";
import EditRelease from "./containers/press-releases/EditRelease";
import AddChampion from "./containers/champions/AddChampion";
import AllChampions from "./containers/champions/AllChampions";
import ViewChampion from "./containers/champions/ViewChampion";
import EditChampion from "./containers/champions/EditChampion";
import AddCalamitie from "./containers/calamities/AddCalamitie";
import AllCalamities from "./containers/calamities/AllCalamities";
import ViewCalamitie from "./containers/calamities/ViewCalamitie";
import EditCalamitie from "./containers/calamities/EditCalamitie";
import AddStorie from "./containers/stories/AddStorie";
import AllStories from "./containers/stories/AllStories";
import ViewStorie from "./containers/stories/ViewStorie";
import EditStorie from "./containers/stories/EditStorie";
import AddEcs from "./containers/ecss/AddEcs";
import AllEcss from "./containers/ecss/AllEcss";
import ViewEcs from "./containers/ecss/ViewEcs";
import EditEcs from "./containers/ecss/EditEcs";
import AddCompanie from "./containers/companies/AddCompanie";
import AllCompanies from "./containers/companies/AllCompanies";
import ViewCompanie from "./containers/companies/ViewCompanie";
import EditCompanie from "./containers/companies/EditCompanie";
import AddTestimonial from "./containers/testimonials/AddTestimonial";
import AllTestimonials from "./containers/testimonials/AllTestimonials";
import ViewTestimonial from "./containers/testimonials/ViewTestimonial";
import EditTestimonial from "./containers/testimonials/EditTestimonial";
import AddBlooddonation from "./containers/blood-donations/AddBlooddonation";
import AllBlooddonations from "./containers/blood-donations/AllBlooddonations";
import ViewBlooddonation from "./containers/blood-donations/ViewBlooddonation";
import EditBlooddonation from "./containers/blood-donations/EditBlooddonation";
import AddBagdonation from "./containers/bag-donations/AddBagdonation";
import AllBagdonations from "./containers/bag-donations/AllBagdonations";
import ViewBagdonation from "./containers/bag-donations/ViewBagdonation";
import EditBagdonation from "./containers/bag-donations/EditBagdonation";

import AddSpecialdonation from "./containers/specialdonations/AddSpecialdonation";
import AllSpecialdonations from "./containers/specialdonations/AllSpecialdonations";
import ViewSpecialdonation from "./containers/specialdonations/ViewSpecialdonation";
import EditSpecialdonation from "./containers/specialdonations/EditSpecialdonation";

function App() {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <BeforeLoginRoutes exact path="/" component={Login} />
          <PrivateRoutes exact path="/dashboard" component={Dashboard} />
          <PrivateRoutes exact path="/profile" component={Profile} />
          <PrivateRoutes exact path="/blogs" component={AllBlogs} />
          <PrivateRoutes exact path="/blogs/add" component={AddBlog} />
          <PrivateRoutes exact path="/blogs/:id/view" component={ViewBlog} />
          <PrivateRoutes exact path="/blogs/:id/edit" component={EditBlog} />
          <PrivateRoutes exact path="/banners" component={AllBanners} />
          <PrivateRoutes exact path="/banners/add" component={AddBanner} />
          <PrivateRoutes
            exact
            path="/banners/:id/view"
            component={ViewBanner}
          />
          <PrivateRoutes
            exact
            path="/banners/:id/edit"
            component={EditBanner}
          />
          <PrivateRoutes exact path="/projects" component={AllProjects} />
          <PrivateRoutes exact path="/projects/add" component={AddProject} />
          <PrivateRoutes
            exact
            path="/projects/:id/view"
            component={ViewProject}
          />
          <PrivateRoutes
            exact
            path="/projects/:id/edit"
            component={EditProject}
          />
          <PrivateRoutes exact path="/causes" component={AllCauses} />
          <PrivateRoutes exact path="/causes/add" component={AddCause} />
          <PrivateRoutes exact path="/causes/:id/view" component={ViewCause} />
          <PrivateRoutes exact path="/causes/:id/edit" component={EditCause} />
          <PrivateRoutes exact path="/donations" component={AllDonations} />
          <PrivateRoutes exact path="/donations/add" component={AddDonation} />
          <PrivateRoutes
            exact
            path="/donations/:id/view"
            component={ViewDonation}
          />
          <PrivateRoutes
            exact
            path="/donations/:id/edit"
            component={EditDonation}
          />
          <PrivateRoutes exact path="/volunteers" component={AllVolunteers} />
          <PrivateRoutes
            exact
            path="/volunteers/add"
            component={AddVolunteer}
          />
          <PrivateRoutes
            exact
            path="/volunteers/:id/view"
            component={ViewVolunteer}
          />
          <PrivateRoutes
            exact
            path="/volunteers/:id/edit"
            component={EditVolunteer}
          />
          <PrivateRoutes exact path="/contacts" component={AllContacts} />
          <PrivateRoutes exact path="/contacts/add" component={AddContact} />
          <PrivateRoutes
            exact
            path="/contacts/:id/view"
            component={ViewContact}
          />
          <PrivateRoutes
            exact
            path="/contacts/:id/edit"
            component={EditContact}
          />
          <PrivateRoutes exact path="/newsletters" component={AllNewsletters} />
          <PrivateRoutes
            exact
            path="/newsletters/add"
            component={AddNewsletter}
          />
          <PrivateRoutes
            exact
            path="/newsletters/:id/view"
            component={ViewNewsletter}
          />
          <PrivateRoutes
            exact
            path="/newsletters/:id/edit"
            component={EditNewsletter}
          />
          <PrivateRoutes exact path="/enquiries" component={AllEnquiries} />
          <PrivateRoutes exact path="/enquiries/add" component={AddEnquirie} />
          <PrivateRoutes
            exact
            path="/enquiries/:id/view"
            component={ViewEnquirie}
          />
          <PrivateRoutes
            exact
            path="/enquiries/:id/edit"
            component={EditEnquirie}
          />

          <PrivateRoutes exact path="/patients" component={AllPatients} />
          <PrivateRoutes exact path="/patients/add" component={AddPatient} />
          <PrivateRoutes
            exact
            path="/patients/:id/view"
            component={ViewPatient}
          />
          <PrivateRoutes
            exact
            path="/patients/:id/edit"
            component={EditPatient}
          />
          <PrivateRoutes exact path="/mobile-banners" component={AllMobiles} />
          <PrivateRoutes
            exact
            path="/mobile-banners/add"
            component={AddMobile}
          />
          <PrivateRoutes
            exact
            path="/mobile-banners/:id/view"
            component={ViewMobile}
          />
          <PrivateRoutes
            exact
            path="/mobile-banners/:id/edit"
            component={EditMobile}
          />
          <PrivateRoutes exact path="/events" component={AllEvents} />
          <PrivateRoutes exact path="/events/add" component={AddEvent} />
          <PrivateRoutes exact path="/events/:id/view" component={ViewEvent} />
          <PrivateRoutes exact path="/events/:id/edit" component={EditEvent} />

          <PrivateRoutes exact path="/press-releases" component={AllReleases} />
          <PrivateRoutes
            exact
            path="/press-releases/add"
            component={AddRelease}
          />
          <PrivateRoutes
            exact
            path="/press-releases/:id/view"
            component={ViewRelease}
          />
          <PrivateRoutes
            exact
            path="/press-releases/:id/edit"
            component={EditRelease}
          />
          <PrivateRoutes exact path="/champions" component={AllChampions} />
          <PrivateRoutes exact path="/champions/add" component={AddChampion} />
          <PrivateRoutes
            exact
            path="/champions/:id/view"
            component={ViewChampion}
          />
          <PrivateRoutes
            exact
            path="/champions/:id/edit"
            component={EditChampion}
          />
          <PrivateRoutes
            exact
            path="/national-calamities"
            component={AllCalamities}
          />
          <PrivateRoutes
            exact
            path="/national-calamities/add"
            component={AddCalamitie}
          />
          <PrivateRoutes
            exact
            path="/national-calamities/:id/view"
            component={ViewCalamitie}
          />
          <PrivateRoutes
            exact
            path="/national-calamities/:id/edit"
            component={EditCalamitie}
          />
          <PrivateRoutes exact path="/success-stories" component={AllStories} />
          <PrivateRoutes
            exact
            path="/success-stories/add"
            component={AddStorie}
          />
          <PrivateRoutes
            exact
            path="/success-stories/:id/view"
            component={ViewStorie}
          />
          <PrivateRoutes
            exact
            path="/success-stories/:id/edit"
            component={EditStorie}
          />
          <PrivateRoutes exact path="/companies" component={AllCompanies} />
          <PrivateRoutes exact path="/companies/add" component={AddCompanie} />
          <PrivateRoutes
            exact
            path="/companies/:id/view"
            component={ViewCompanie}
          />
          <PrivateRoutes
            exact
            path="/companies/:id/edit"
            component={EditCompanie}
          />
          <Route exact path="/blooddonations" component={AllBlooddonations} />
          <Route
            exact
            path="/blooddonations/add"
            component={AddBlooddonation}
          />
          <Route
            exact
            path="/blooddonations/:id/view"
            component={ViewBlooddonation}
          />
          <Route
            exact
            path="/blooddonations/:id/edit"
            component={EditBlooddonation}
          />
          <PrivateRoutes exact path="/bagdonations" component={AllBagdonations} />
          <PrivateRoutes
            exact
            path="/bagdonations/add"
            component={AddBagdonation}
          />
          <PrivateRoutes
            exact
            path="/bagdonations/:id/view"
            component={ViewBagdonation}
          />
          <PrivateRoutes
            exact
            path="/bagdonations/:id/edit"
            component={EditBagdonation}
          />
          <PrivateRoutes exact path="/ecss" component={AllEcss} />
          <PrivateRoutes exact path="/ecss/add" component={AddEcs} />
          <PrivateRoutes exact path="/ecss/:id/view" component={ViewEcs} />
          <PrivateRoutes exact path="/ecss/:id/edit" component={EditEcs} />
          <PrivateRoutes
            exact
            path="/testimonials"
            component={AllTestimonials}
          />
          <PrivateRoutes
            exact
            path="/testimonials/add"
            component={AddTestimonial}
          />
          <PrivateRoutes
            exact
            path="/testimonials/:id/view"
            component={ViewTestimonial}
          />
          <PrivateRoutes
            exact
            path="/testimonials/:id/edit"
            component={EditTestimonial}
          />

          <PrivateRoutes exact path="/specialdonations" component={AllSpecialdonations} />
          <PrivateRoutes
            exact
            path="/specialdonations/add"
            component={AddSpecialdonation}
          />
          <PrivateRoutes
            exact
            path="/specialdonations/:id/view"
            component={ViewSpecialdonation}
          />
          <PrivateRoutes
            exact
            path="/specialdonations/:id/edit"
            component={EditSpecialdonation}
          />

          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
