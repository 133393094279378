import {
    GET_SPECIALDONATIONS,
    ADD_SPECIALDONATION,
    EDIT_SPECIALDONATION,
    GET_SPECIALDONATION,
    RESET_SPECIALDONATION,
    SPECIALDONATIONS_ERROR,
} from "../types/specialdonation_type";

const initialState = {
    specialdonations: null,
    specialdonation: null,
    total_specialdonations: 0,
    loading: true,
    error: {},
    specialdonation_message: null,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_SPECIALDONATIONS:
            return {
                ...state,
                specialdonations: payload,
                loading: false,
                error: {},
            };

        case RESET_SPECIALDONATION:
            return {
                ...state,
                specialdonation: null,
                loading: true,
                error: {},
            };

        case ADD_SPECIALDONATION:
            return {
                ...state,
                specialdonation_message: payload,
                loading: false,
                error: {},
            };
        case GET_SPECIALDONATION:
            return {
                ...state,
                specialdonation: payload,
                loading: false,
                error: {},
            };
        case EDIT_SPECIALDONATION:
            return {
                ...state,
                specialdonation_message: payload,
                loading: false,
                error: {},
            };

        case SPECIALDONATIONS_ERROR:
            return {
                ...state,
                loading: false,
                error: payload,
            };

        default:
            return state;
    }
}
