export const MENU = [
  {
    label: "Dashboard",
    link: "/dashboard",
  },
  {
    label: "Campaigns",
    link: "#",
    menu: [
      {
        label: "Projects",
        link: "#",
        menu: [
          { label: "View Projects", link: "/projects" },
          { label: "Add Project", link: "/projects/add" },
        ],
      },
      {
        label: "Causes",
        link: "#",
        menu: [
          { label: "View Causes", link: "/causes" },
          { label: "Add Cause", link: "/causes/add" },
        ],
      },
      {
        label: "National Calamities",
        link: "#",
        menu: [
          { label: "View National Calamities", link: "/national-calamities" },
          {
            label: "Add National Calamity",
            link: "/national-calamities/add",
          },
        ],
      },
      {
        label: "Success Stories",
        link: "#",
        menu: [
          { label: "View Success Stories", link: "/success-stories" },
          {
            label: "Add Success Story",
            link: "/success-stories/add",
          },
        ],
      },
      {
        label: "Champions",
        link: "#",
        menu: [
          { label: "View Champions", link: "/champions" },
          {
            label: "Add Champion",
            link: "/champions/add",
          },
        ],
      },
    ],
  },
  {
    label: "Donations",
    link: "#",
    menu: [
      {
        label: "Donations",
        link: "#",
        menu: [
          { label: "View Donations", link: "/donations" },
          // { label: "Add Donations", link: "/donations/add" },
        ],
      },
      {
        label: "Blood Donations",
        link: "#",
        menu: [
          { label: "View Blood Donations", link: "/blooddonations" },
          // { label: "Add Blood Donations", link: "/blood-donations/add" },
        ],
      },
      {
        label: "Bag Donations",
        link: "#",
        menu: [
          { label: "View Bag Donations", link: "/bagdonations" },
          // { label: "Add Blood Donations", link: "/blood-donations/add" },
        ],
      },
      {
        label: "Special Donations",
        link: "#",
        menu: [
          { label: "View Special Donations", link: "/specialdonations" },
          // { label: "Add Blood Donations", link: "/blood-donations/add" },
        ],
      },
    ],
  },
  {
    label: "ECS",
    link: "/ecss",
  },
  {
    label: "Gallery",
    link: "#",
    menu: [
      {
        label: "Events",
        link: "#",
        menu: [
          { label: "View Events", link: "/events" },
          { label: "Add Event", link: "/events/add" },
        ],
      },
      {
        label: "Press Releases",
        link: "#",
        menu: [
          { label: "View Press Releases", link: "/press-releases" },
          { label: "Add Press Releases", link: "/press-releases/add" },
        ],
      },
    ],
  },
  {
    label: "Blogs",
    link: "/blogs",
  },
  {
    label: "Enquiries",
    link: "#",
    menu: [
      {
        label: "Contacts",
        link: "#",
        menu: [
          { label: "View Contacts", link: "/contacts" },
          { label: "Add Contact", link: "/contacts/add" },
        ],
      },
      {
        label: "Newsletters",
        link: "#",
        menu: [
          { label: "View Newsletters", link: "/newsletters" },
          { label: "Add Newsletter", link: "/newsletters/add" },
        ],
      },
      {
        label: "Enquiries",
        link: "#",
        menu: [
          { label: "View Enquiries", link: "/enquiries" },
          { label: "Add Enquiry", link: "/enquiries/add" },
        ],
      },
      {
        label: "Patients",
        link: "#",
        menu: [
          { label: "View Patients", link: "/patients" },
          { label: "Add Patient", link: "/patients/add" },
        ],
      },
      {
        label: "Volunteers",
        link: "#",
        menu: [
          { label: "View Volunteers", link: "/volunteers" },
          { label: "Add Volunteer", link: "/volunteers/add" },
        ],
      },
      {
        label: "Testimonials",
        link: "#",
        menu: [
          { label: "View Testimonials", link: "/testimonials" },
          { label: "Add Testimonial", link: "/testimonials/add" },
        ],
      },
      {
        label: "Companies",
        link: "#",
        menu: [
          { label: "View Companies", link: "/companies" },
          { label: "Add Company", link: "/companies/add" },
        ],
      },
    ],
  },
  {
    label: "Banners",
    link: "#",
    menu: [
      {
        label: "Banners",
        link: "#",
        menu: [
          { label: "View Banners", link: "/banners" },
          { label: "Add Banner", link: "/banners/add" },
        ],
      },
      {
        label: "Mobile Banners",
        link: "#",
        menu: [
          { label: "View Mobile Banners", link: "/mobile-banners" },
          { label: "Add Mobile Banner", link: "/mobile-banners/add" },
        ],
      },
    ],
  },
];
