export const PAGE_TITLE = "Champions";
export const PAGE_SINGLE_TITLE = "Champion";
export const LINK_URL = "champions";
export const SEARCH_TERMS = ["name"];
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
    slug: true,
    inputType: "text",
  },
  age: {
    type: "string",
    required: true,
    title: "Age",
  },

  content: {
    type: "html",
    required: false,
    title: "Content",
  },
  featured_image: {
    type: "file",
    required: false,
    title: "Image",
  },
  document: {
    type: "gallery",
    required: false,
    title: "Document",
  },

  slug: {
    type: "slug",
    required: false,
    targetField: "title",
    title: "Slug",
  },
};
export const initialValues = {
  name: "",
  age: "",

  content: "",
  slug: "",
};

export const view_all_table = [
  { name: "Name", value: "name" },
  { name: "Age", value: "age" },

  { name: "Slug", value: "slug" },
];
