export const PAGE_TITLE = "Newsletters";
export const PAGE_SINGLE_TITLE = "Newsletter";
export const LINK_URL = "newsletters";
export const SEARCH_TERMS = ["email"];
export const inputFields = {
  email: {
    type: "string",
    required: true,
    title: "Email",
  },
};
export const initialValues = {
  email: "",
};

export const view_all_table = [{ name: "Email", value: "email" }];
