export const PAGE_TITLE = "Patients";
export const PAGE_SINGLE_TITLE = "Patient";
export const LINK_URL = "patients";
export const SEARCH_TERMS = ["title", "meta_title"];
export const inputFields = {
  patient_name: {
    type: "string",
    required: true,
    title: "Patient Name",
  },
  email: {
    type: "string",
    required: true,
    title: "Email",
  },
  phone: {
    type: "string",
    required: true,
    title: "Phone",
  },
  patient_picture: {
    type: "file",
    required: false,
    title: "Patient Picture",
  },
  patient_document: {
    type: "gallery",
    required: false,
    title: "Patient Document",
  },
  local_address: {
    type: "text",
    required: false,
    title: "Local Address",
  },
  permanent_address: {
    type: "text",
    required: false,
    title: "Permanent Address",
  },
  patient_description: {
    type: "html",
    required: true,
    title: "Patient Description",
  },
};
export const initialValues = {
  patient_name: "",
  email: "",
  phone: "",
  local_address: "",
  permanent_address: "",
  patient_description: "",
};

export const view_all_table = [
  { name: "Patient Name", value: "patient_name" },
  { name: "Phone", value: "phone" },
  { name: "Email", value: "email" },
  { name: "Local Address", value: "local_address" },
];
