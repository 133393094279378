import React, { useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { getStorie } from "../../store/actions/stories_action";
import Spinner from "../../components/layout/Spinner";
import {
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/stories_enum";
import SingleView from "../../components/common/SingleView";
const ViewStorie = ({ getStorie, match, storie: { loading, storie } }) => {
  useEffect(() => {
    getStorie(match.params.id);
  }, [match]);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_SINGLE_TITLE}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="View"
        />
        {!loading ? (
          storie && (
            <SingleView
              data={storie}
              inputFields={inputFields}
              label={PAGE_SINGLE_TITLE}
              link={LINK_URL}
              id={storie.id}
              hideGallery={true}
            />
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ storie: state.storie });

const mapDispatchToProps = { getStorie };

export default connect(mapStateToProps, mapDispatchToProps)(ViewStorie);
