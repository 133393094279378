export const SEARCH_TERMS = ["name", "phone"];
export const inputFields = {
    products_in_numbers: {
        type: "string",
        required: true,
        title: "Products In Number",
        slug: true,
        inputType: "text",
    },
    product: {
        type: "string",
        required: true,
        title: "Products In Number",
        slug: true,
        inputType: "text",
    },
    name: {
        type: "string",
        required: true,
        title: "Name",
        slug: true,
        inputType: "text",
    },
    email: {
        type: "string",
        required: true,
        title: "Email",
        slug: true,
        inputType: "text",
    },
    phone: {
        type: "string",
        required: true,
        title: "Phone",
        slug: true,
        inputType: "text",
    },
    message: {
        type: "string",
        required: true,
        title: "Message",
        slug: true,
        inputType: "text",
    },

};
export const initialValues = {
    name: "",
    phone: "",
    email: "",
    message: "",
    products_in_numbers: "",
    product: ""
};

export const view_all_table = [
    { name: "Name", value: "name" },
    { name: "Phone", value: "phone" },
    { name: "Email", value: "email" },
    { name: "Product", value: "product" },

];
