export const PAGE_TITLE = "Enquiries";
export const PAGE_SINGLE_TITLE = "Enquiry";
export const LINK_URL = "enquiries";
export const SEARCH_TERMS = ["name", "phone", "email"];
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
  },
  email: {
    type: "string",
    required: true,
    title: "Email",
  },
  phone: {
    type: "string",
    required: true,
    title: "Phone",
  },
  subject: {
    type: "string",
    required: true,
    title: "Subject",
  },
  message: {
    type: "text",
    required: true,
    title: "Message",
  },
};
export const initialValues = {
  name: "",
  email: "",
  phone: "",
  subject: "",
  message: "",
};

export const view_all_table = [
  { name: "Name", value: "name" },
  { name: "Email", value: "email" },
  { name: "Phone", value: "phone" },
  { name: "Subject", value: "subject" },
];
