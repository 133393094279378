import React, { useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { getProject } from "../../store/actions/projects_action";
import Spinner from "../../components/layout/Spinner";
import {
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/projects_enum";
import SingleView from "../../components/common/SingleView";
const ViewProject = ({ getProject, match, project: { loading, project } }) => {
  useEffect(() => {
    getProject(match.params.id);
  }, [match]);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_SINGLE_TITLE}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="View"
        />
        {!loading ? (
          project && (
            <SingleView
              data={project}
              inputFields={inputFields}
              label={PAGE_SINGLE_TITLE}
              link={LINK_URL}
              id={project.id}
            />
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ project: state.project });

const mapDispatchToProps = { getProject };

export default connect(mapStateToProps, mapDispatchToProps)(ViewProject);
