import React, { useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import renderHTML from "react-render-html";
import { getBlooddonation } from "../../store/actions/blooddonations_action";
import Spinner from "../../components/layout/Spinner";
import SingleItem from "../../components/template/SingleItem";
import { Link } from "react-router-dom";
const ViewBlooddonation = ({ getBlooddonation, match, blooddonation: { loading, blooddonation } }) => {
    useEffect(() => {
        getBlooddonation(match.params.id);
    }, [match]);
    return (
        <div className="pace-done">
            <div>
                <Header />
                <BreadCrumb
                    title="Blooddonations"
                    mainLinkTitle="Blooddonations"
                    mainLinkUrl="/blooddonations"
                    activeLink="View"
                />
                {!loading ? (
                    blooddonation && (
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="card">
                                        <div className="card-header">
                                            <div>
                                                <h4 className="card-title">
                                                    {blooddonation.name} <span>View</span>
                                                </h4>
                                                <p className="card-title-desc">
                                                    <Link to="/blooddonations" className="btn btn-soft-light">
                                                        <i className="fa fa-angle-left"></i> Blooddonations
                                                    </Link>
                                                    <Link
                                                        to={`/blooddonations/${blooddonation.id}/edit`}
                                                        className="btn btn-soft-light"
                                                    >
                                                        <i className="fa fa-edit"></i>
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <SingleItem title="title" value={blooddonation.title} />

                                            {blooddonation.featured_image && (
                                                <SingleItem title="Featured Image">
                                                    <img
                                                        src={`${blooddonation.featured_image}`}
                                                        style={{
                                                            width: "100%",
                                                            height: "200px",
                                                            objectFit: "contain",
                                                        }}
                                                    />
                                                </SingleItem>
                                            )}
                                            {blooddonation.gallery && (
                                                <SingleItem title="Gallery">
                                                    {blooddonation.gallery.map((pic) => {
                                                        return (
                                                            <img
                                                                src={pic}
                                                                style={{
                                                                    width: "100%",
                                                                    height: "100px",
                                                                    objectFit: "contain",
                                                                }}
                                                            />
                                                        );
                                                    })}
                                                </SingleItem>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="card">
                                        <div className="card-header"> Content </div>
                                        <div className="card-body">
                                            <div className="card-content">
                                                {blooddonation.content && renderHTML(blooddonation.content)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                ) : (
                    <div>
                        <Spinner />
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({ blooddonation: state.blooddonation });

const mapDispatchToProps = { getBlooddonation };

export default connect(mapStateToProps, mapDispatchToProps)(ViewBlooddonation);
