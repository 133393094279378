export const PAGE_TITLE = "Donations";
export const PAGE_SINGLE_TITLE = "Donation";
export const LINK_URL = "donations";
export const SEARCH_TERMS = ["amount", "donor_name", "phone", "volunteer"];
export const inputFields = {
  donor_name: {
    type: "string",
    required: true,
    title: "Donor Name",
  },
  email: {
    type: "string",
    required: true,
    title: "Email",
  },
  phone: {
    type: "string",
    required: true,
    title: "Phone",
  },
  pan: {
    type: "string",
    required: false,
    title: "PAN",
  },
  volunteer: {
    type: "string",
    required: false,
    title: "Volunteer",
  },
  amount: {
    type: "string",
    required: true,
    title: "Amount",
    inputType: "number",
  },
  city: {
    type: "string",
    required: false,
    title: "City",
  },
  address: {
    type: "string",
    required: false,
    title: "Address",
  },
  pincode: {
    type: "string",
    required: false,
    title: "Pincode",
  },
  state: {
    type: "string",
    required: false,
    title: "State",
  },
  message: {
    type: "text",
    required: false,
    title: "Message",
  },
  transaction_id: {
    type: "string",
    required: false,
    title: "Transaction ID",
  },
  page: {
    type: "string",
    required: false,
    title: "Page",
  },
  referrer: {
    type: "string",
    required: false,
    title: "Referrer",
  },
};
export const initialValues = {
  donor_name: "",
  email: "",
  phone: "",
  pan: "",
  volunteer: "",
  amount: "",
  city: "",
  address: "",
  pincode: "",
  state: "",
  message: "",
  transaction_id: "",
  referrer: "",
};

export const view_all_table = [
  { name: "Amount", value: "amount" },
  { name: "Donor Name", value: "donor_name" },
  { name: "Phone", value: "phone" },
  { name: "Email", value: "email" },
  { name: "Trans id", value: "transaction_id" },
  { name: "Page", value: "page" },
  { name: "Referrer", value: "referrer" },
  { name: "Volunteer", value: "volunteer" },
];
