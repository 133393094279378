import React, { useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import renderHTML from "react-render-html";
import { getBagdonation } from "../../store/actions/bagdonations_action";
import Spinner from "../../components/layout/Spinner";
import SingleItem from "../../components/template/SingleItem";
import { Link } from "react-router-dom";
const ViewBagdonation = ({ getBagdonation, match, bagdonation: { loading, bagdonation } }) => {
    useEffect(() => {
        getBagdonation(match.params.id);
    }, [match]);
    return (
        <div className="pace-done">
            <div>
                <Header />
                <BreadCrumb
                    title="Bagdonations"
                    mainLinkTitle="Bagdonations"
                    mainLinkUrl="/bagdonations"
                    activeLink="View"
                />
                {!loading ? (
                    bagdonation && (
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="card">
                                        <div className="card-header">
                                            <div>
                                                <h4 className="card-title">
                                                    {bagdonation.name} <span>View</span>
                                                </h4>
                                                <p className="card-title-desc">
                                                    <Link to="/bagdonations" className="btn btn-soft-light">
                                                        <i className="fa fa-angle-left"></i> Bagdonations
                                                    </Link>
                                                    <Link
                                                        to={`/bagdonations/${bagdonation.id}/edit`}
                                                        className="btn btn-soft-light"
                                                    >
                                                        <i className="fa fa-edit"></i>
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <SingleItem title="title" value={bagdonation.title} />

                                            {bagdonation.featured_image && (
                                                <SingleItem title="Featured Image">
                                                    <img
                                                        src={`${bagdonation.featured_image}`}
                                                        style={{
                                                            width: "100%",
                                                            height: "200px",
                                                            objectFit: "contain",
                                                        }}
                                                    />
                                                </SingleItem>
                                            )}
                                            {bagdonation.gallery && (
                                                <SingleItem title="Gallery">
                                                    {bagdonation.gallery.map((pic) => {
                                                        return (
                                                            <img
                                                                src={pic}
                                                                style={{
                                                                    width: "100%",
                                                                    height: "100px",
                                                                    objectFit: "contain",
                                                                }}
                                                            />
                                                        );
                                                    })}
                                                </SingleItem>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="card">
                                        <div className="card-header"> Content </div>
                                        <div className="card-body">
                                            <div className="card-content">
                                                {bagdonation.content && renderHTML(bagdonation.content)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                ) : (
                    <div>
                        <Spinner />
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({ bagdonation: state.bagdonation });

const mapDispatchToProps = { getBagdonation };

export default connect(mapStateToProps, mapDispatchToProps)(ViewBagdonation);
