import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import {
    getBagdonations,
    deleteBagdonation,
    getNextBagdonations,
    getPreviousBagdonations,
} from "../../store/actions/bagdonations_action";
import { LIMIT } from "../../domain/constant";
import Pagination from "../../components/common/Pagination";
import Filters from "../../components/common/Filters";
import AddBtn from "../../components/common/AddBtn";
import { SEARCH_TERMS, view_all_table } from "../../shared/enums/bagdonations_enum";
import DataTable from "../../components/common/DataTable";
const AllBagdonations = ({
    getBagdonations,
    deleteBagdonation,
    getNextBagdonations,
    getPreviousBagdonations,
    bagdonation: { loading, bagdonations },
}) => {
    const [page, setPage] = useState(1);
    const [deleteEntry, setDeleteEntry] = useState(null);
    const [q, setQ] = useState(null);
    const [term, setTerm] = useState(null);
    const [termField, setTermField] = useState(SEARCH_TERMS[0]);
    useEffect(() => {
        async function allQuery() {
            if (deleteEntry) {
                await deleteBagdonation(deleteEntry);
            }
            getBagdonations({});
        }
        allQuery();
    }, [deleteEntry]);

    const nextButtonClicked = () => {
        if (bagdonations && bagdonations.length === LIMIT) {
            const lastBagdonation = bagdonations[bagdonations.length - 1];
            getNextBagdonations({ last: lastBagdonation });
            setPage(page + 1);
        }
    };
    const prevBtnClicked = () => {
        if (page > 1) {
            const lastBagdonation = bagdonations[0];
            getPreviousBagdonations({
                first: lastBagdonation,
            });
            setPage(page - 1);
        }
    };
    const filterClicked = () => {
        if (term && term.length > 0) {
            setPage(1);
            setQ(term);
            getBagdonations({ term: term, termField: termField });
        }
    };
    const deleteBagdonationClicked = (id) => {
        setDeleteEntry(id);
    };
    const resetFilter = () => {
        setPage(1);
        setQ(null);
        setTerm("");
        getBagdonations({});
    };

    return (
        <div className="pace-done">
            <div>
                <Header />
                <BreadCrumb
                    title="Bagdonations"
                    mainLinkTitle="Dashboard"
                    mainLinkUrl="/dashboard"
                    activeLink="Bagdonation"
                />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="card"
                                style={{ boxShadow: "rgb(227 233 243) 0px 4px 22px" }}
                            >
                                <div className="card-body">
                                    <AddBtn item="bagdonations" title="Bagdonation" />
                                    <hr />

                                    <Filters
                                        q={q}
                                        resetFilter={resetFilter}
                                        setTerm={setTerm}
                                        term={term}
                                        filterClicked={filterClicked}
                                        termField={termField}
                                        setTermField={setTermField}
                                        all_terms={SEARCH_TERMS}
                                    />
                                    <hr />
                                    <div>
                                        <DataTable
                                            keys={view_all_table}
                                            data={bagdonations}
                                            field={"bagdonations"}
                                            page={page}
                                            deleteBtnClicked={deleteBagdonationClicked}
                                            loading={loading}
                                        />
                                        <Pagination
                                            data={bagdonations}
                                            page={page}
                                            prevBtnClicked={prevBtnClicked}
                                            nextButtonClicked={nextButtonClicked}
                                            loading={loading}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({ bagdonation: state.bagdonation });

const mapDispatchToProps = {
    getBagdonations,
    deleteBagdonation,
    getNextBagdonations,
    getPreviousBagdonations,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllBagdonations);
