import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { getBagdonation, editBagdonation } from "../../store/actions/bagdonations_action";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../../components/layout/Spinner";
import AddForm from "../../components/common/AddForm";
import { upload } from "../../shared/upload";
import { initialValues, inputFields } from "../../shared/enums/bagdonations_enum";

const EditBagdonation = ({ editBagdonation, getBagdonation, match, bagdonation: { loading, bagdonation } }) => {
    let history = useHistory();
    useEffect(() => {
        getBagdonation(match.params.id);
    }, [match]);
    const [featuredImage, setFeaturedImage] = useState(null);
    const [gallery, setGallery] = useState(null);
    const submitBagdonation = async (values) => {
        if (featuredImage || gallery) {
            const images = await upload(featuredImage, gallery);
            if (images) {
                if (images.featured_image) {
                    values.featured_image = images.featured_image;
                }
                if (images.gallery_images) {
                    values.gallery = images.gallery_images;
                }
            }
        }
        await editBagdonation(bagdonation.id, values);
        history.push(`/bagdonations/${bagdonation.id}/view`);
    };

    return (
        <div className="pace-done">
            <div>
                <Header />
                <BreadCrumb
                    title="Edit Bagdonation"
                    mainLinkTitle="Bagdonations"
                    mainLinkUrl="/bagdonations"
                    activeLink="Edit"
                />
            </div>
            <div className="container-fluid">
                <div className="col-lg-12">
                    <div className="card">
                        {!loading ? (
                            bagdonation && (
                                <div>
                                    <div className="card-header">
                                        <div>
                                            <h4 className="card-title">
                                                {bagdonation.title} - <span>Edit</span>{" "}
                                            </h4>
                                            <p className="card-title-desc">
                                                <Link to="/bagdonations" className="btn btn-soft-light">
                                                    <i className="fa fa-angle-left"></i> Bagdonations
                                                </Link>
                                                <Link
                                                    to={`/bagdonations/${bagdonation.id}/view`}
                                                    className="btn btn-soft-light"
                                                >
                                                    <i className="fa fa-eye"></i>
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                    <AddForm
                                        data={bagdonation}
                                        edit={true}
                                        featuredImage={featuredImage}
                                        gallery={gallery}
                                        setFeaturedImage={setFeaturedImage}
                                        setGallery={setGallery}
                                        submitForm={submitBagdonation}
                                        inputFields={inputFields}
                                        initialValues={initialValues}
                                    />
                                </div>
                            )
                        ) : (
                            <div className="text-center">
                                <Spinner />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    alert: state.alert,
    bagdonation: state.bagdonation,
});

const mapDispatchToProps = { editBagdonation, getBagdonation };

export default connect(mapStateToProps, mapDispatchToProps)(EditBagdonation);
