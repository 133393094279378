export const PAGE_TITLE = "Press Releases";
export const PAGE_SINGLE_TITLE = "Press Release";
export const LINK_URL = "press-releases";
export const SEARCH_TERMS = ["title"];
export const inputFields = {
  title: {
    type: "string",
    required: true,
    title: "Title",
  },
  link: {
    type: "string",
    required: false,
    title: "Link",
  },
  featured_image: {
    type: "file",
    required: false,
    title: "Featured Image",
  },
};
export const initialValues = {
  title: "",
  link: "",
};

export const view_all_table = [{ name: "Title", value: "title" }];
