export const PAGE_TITLE = "Companies";
export const PAGE_SINGLE_TITLE = "Company";
export const LINK_URL = "companies";
export const SEARCH_TERMS = ["name"];
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "name",
  },
  image: {
    type: "file",
    required: false,
    title: "Image",
  },
};
export const initialValues = {
  name: "",
};

export const view_all_table = [{ name: "Name", value: "name" }];
